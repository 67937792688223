<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiKeyChain }}
      </v-icon>
      <span class="text-break">Factor Enrollments</span>
    </v-card-title>

    <enrollments-table
      :filter="filter"
      type="enrollment"
      title="Enrollments"
      :hide-title="true"
    ></enrollments-table>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiKeyChain, mdiPencilOutline, mdiDeleteOutline,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'
import EnrollmentsTable from '../../enrollment/EnrollmentsTable.vue'

export default {
  components: {
    EnrollmentsTable,
  },
  data() {
    return {
    }
  },
  methods: {
    cancel() {
      this.$router.back()
    },
  },
  setup() {
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const filter = { account: loginStatus.sub, type: 'enrollment' }

    return {
      loginStatus,
      status,
      filter,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiKeyChain,
      },
    }
  },
}
</script>
